/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    ul: "ul",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The G2 Spring 2022 Reports have been published, and Prismatic has once again been named a High Performer in the long-standing iPaaS category. We have also earned High Performer status on the G2 Grid for its newly-established category for Embedded Integration Platforms!"), "\n", React.createElement(_components.p, null, "G2 is a trusted source of business software review data, with over 1.6 million user reviews that help buyers make informed decisions based on the experiences of their peers. Based on its extensive user review data, the site produces Grids identifying the top products in each category and publishes quarterly Grid Reports."), "\n", React.createElement(_components.p, null, "The Spring 2022 Grid Report for iPaaS has once again recognized Prismatic as a High Performer. We're proud of this position and proud to be ", React.createElement(_components.a, {
    href: "https://www.g2.com/products/prismatic/reviews",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "a unique product in G2's iPaaS category"), ". That's because the vast majority of products in the iPaaS systems were designed to help businesses integrate the software they use internally, while here at Prismatic, we are laser-focused on helping B2B software companies deliver product integrations for their customers."), "\n", React.createElement(_components.p, null, "B2B software teams represent an entirely different set of users – one we're extremely passionate about serving – with an entirely different use case and in need of an entirely different type of integration platform."), "\n", React.createElement(_components.p, null, "That's why we are so excited to share that G2 has just created a new category for exactly the type of product we have pioneered: Embedded Integration Platforms. The creation of this category recognizes software teams' critical need for better ways to build in-app integrations and create high-quality integration experiences for their users. Here's how G2 defines it:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Embedded integration platforms, also called ", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), ", allow product teams to build, govern, and manage native integrations with their customers' preferred third-party applications. While traditional iPaaS platforms focus on connecting and automating internal functions, ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platforms"), " allow software providers to build and deliver integrations for their users."), "\n"), "\n", React.createElement(_components.p, null, "We're even more excited to share that Prismatic sits at the top of the High Performers list in G2's real-time rankings for Embedded Integration Platforms. Here are some highlights from the user review data used to determine that placement:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Prismatic has earned a 4.9-star rating on G2"), "\n", React.createElement(_components.li, null, "97% of Prismatic reviewers say they are likely to recommend the product"), "\n", React.createElement(_components.li, null, "Prismatic has the third-highest ", React.createElement(_components.a, {
    href: "https://www.g2.com/categories/embedded-integration-platforms?tab=highest_rated",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "G2 Satisfaction Score for Embedded Integration Platforms"), " – and the ", React.createElement(_components.em, null, "highest"), " among products in the category whose users are primarily software companies"), "\n"), "\n", React.createElement(_components.p, null, "Here are some of the things our users have said about their experiences using Prismatic:"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Prismatic's service has allowed us to quickly and easily add B2B integrations in our system with minimal pain.")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "I love the simplicity of their embedded integration platform. As a developer working in integrations, one of the last things that I want to deal with is the headache of managing all the connections and endpoints. Their system makes it easy to manage all things integrations.")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Prismatic's integration platform has seriously upped our game from previous integration solutions like Zapier.")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "The ability to develop custom components for your integrations means your integration steps can do anything you want to code it to do. Highly flexible, and I enjoy working with the platform!")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "The product is allowing us to create an integration hub for our customers, which is very necessary! We evaluated multiple iPaaS vendors, and Prismatic was hands down the best solution with the most robust offering.")), "\n", React.createElement(_components.p, null, "I invite you to visit ", React.createElement(_components.a, {
    href: "https://www.g2.com/products/prismatic/reviews",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Prismatic's G2 profile"), " to read more of our user reviews."), "\n", React.createElement(_components.p, null, "Were thrilled to be at the forefront of this new software category, and we view the high satisfaction ratings from our customers as important validation that we're making significant strides in our mission to make integrations easier for B2B software companies."), "\n", React.createElement(_components.p, null, "If you're looking for a better way to deliver integrations to your customers, we'd love to ", React.createElement(_components.a, {
    href: "/contact/"
  }, "have a conversation"), " about your integration needs and how our top-rated ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), " can help."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
