import GATSBY_COMPILED_MDX from "/builds/prismatic-io/website/content/blog/prismatic-g2-embedded-integration-platforms-ipaas.mdx";
import React from "react";
import {graphql} from "gatsby";
import {BlogPost, BlogContainer} from "@/page-components/blog";
import {SEO} from "@/layout/SEO";
const PostTemplate = ({data, location, children}) => {
  return React.createElement(BlogContainer, {
    useScroll: true
  }, React.createElement(BlogPost, {
    markdown: children,
    post: data.post,
    authors: data.authors.frontmatter.authors,
    location: location,
    aboutPrismatic: data.aboutPrismatic
  }));
};
export const query = graphql`
  query($slug: String!) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...MdxSeoFields
        datePublished(formatString: "YYYY-MM-DD")
        dateModified(formatString: "YYYY-MM-DD")
        author
        category
        featureImage {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              quality: 100
              width: 800
            )
          }
        }
      }
      fields {
        slug
        timeToRead {
          minutes
        }
      }
    }
    authors: markdownRemark(
      fileAbsolutePath: { glob: "**/content/site-meta.md" }
    ) {
      frontmatter {
        authors {
          name
          title
          social
          avatar {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                placeholder: NONE
                quality: 85
                width: 56
              )
            }
          }
        }
      }
    }
    aboutPrismatic: markdownRemark(
      fileAbsolutePath: { glob: "**/content/blog-about-prismatic.md" }
    ) {
      html
    }
  }
`;
export const Head = ({location, data, params, pageContext}) => {
  const {author, datePublished, dateModified, description, featureImage, metaDescription, robots, schemaOrg, seoImage, title, titleTag} = data.post.frontmatter;
  return React.createElement(SEO, {
    author: author,
    description: description,
    featureImage: featureImage,
    metaDescription: metaDescription,
    pathname: location.pathname,
    robots: robots,
    schemaOrg: schemaOrg,
    seoImage: seoImage,
    title: title,
    titleTag: titleTag
  }, React.createElement("script", {
    type: "application/ld+json"
  }, JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: titleTag || title,
    description: metaDescription || description,
    datePublished,
    dateModified,
    mainEntityOfPage: "True",
    image: seoImage?.childImageSharp.gatsbyImageData.images.fallback.src || featureImage?.childImageSharp.gatsbyImageData.images.fallback.src,
    publisher: {
      "@type": "Organization",
      name: "Prismatic"
    },
    author: author ? {
      "@type": "Person",
      name: author
    } : undefined
  }, undefined, 2)));
};
PostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
